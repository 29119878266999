/**
 * Type: コンポーネント
 * What: SEO(meta,ogp)
 */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description, lang, meta, title,ogImage
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            baseUrl
            title
            description
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const url = site.siteMetadata.baseUrl;
  return (
    <Helmet
      title={title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s｜${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'baidu-site-verification',
          content: 'FyV0JjRrve',
        },
        {
          property: 'og:title',
          content: `${title}｜${site.siteMetadata.title}`,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: ogImage ? `https://e-zofukuoka.com${ogImage}` : 'https://e-zofukuoka.com/ogp.jpg',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: `${title}｜${site.siteMetadata.title}`,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'facebook-domain-verification',
          content: 'ih156p2weucycf0hbqkmbsap0rbs76',
        },
      ].concat(meta)}
    >
      <script src="https://cdn-blocks.karte.io/6d46158bc9f72b07ebb35ac4bcc9d483/builder.js" />
      <script defer src="https://use.fontawesome.com/releases/v5.3.1/js/all.js" />
      <link
        rel="alternate"
        hrefLang="en"
        href={`${url}/en/index.html`}
      />
      <link
        rel="alternate"
        hrefLang="ko"
        href={`${url}/ko/index.html`}
      />
      <link
        rel="alternate"
        hrefLang="zh-Hans"
        href={`${url}/zh-CHS/index.html`}
      />
      <link
        rel="alternate"
        hrefLang="zh-Hant"
        href={`${url}/zh-CHT/index.html`}
      />
      <link
        rel="alternate"
        hrefLang="ja"
        href={`${url}/index.html`}
      />
      <link
        rel="apple-touch-icon"
        href="https://e-zofukuoka.com/touch.png"
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'ja',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
